import { BaseUrl } from './ApiUrl'
import AXIOS_SERVICE from '../utils/AxiosUtil'

const DashboardService = () => {}

DashboardService.GetDashboard = async()=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/dashboard/`, {})
}




 export default DashboardService