<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="12" md="12">
        <analytics-congratulation  />
      </b-col>   
    </b-row>

    <b-row class="match-height">
      <b-col lg="3" md="12">
        <b-card  style="padding-top:2%" class="border-primary">
          <b-col sm="12" style="font-size:1.2em">    
            <h1 class="text-primary">Ticket</h1>                  
            Inprogress : {{ result.tickets.inprogress }} <br/>
            Closed : {{ result.tickets.closed }} <br/>
            Cancel : {{ result.tickets.cancel }}  
          </b-col>
        </b-card>
      </b-col>

      <b-col lg="3" md="12">
      <b-card  style="padding-top:2%" class="border-primary">
          <b-col sm="12" style="font-size:1.2em">    
            <h1 class="text-primary">Stock</h1>                  
              Onhand : {{ result.stock.onhand }} <br/>
              Repair (Wait) : {{ result.stock.repair }} <br/>
              Repair (Complete) : {{ result.stock.repairComplete }}  
          </b-col>
        </b-card>
      </b-col>

      <b-col lg="3" sm="6">
        <b-card  style="padding-top:2%" class="border-primary">
          <b-col sm="12" style="font-size:1.2em">    
            <h1 class="text-primary">Accounting</h1>                  
            Wait : {{ result.accounting.wait }} <br/>
            Approved : {{ result.accounting.approved }}         
          </b-col>
        </b-card>
      </b-col>

      <b-col lg="3" sm="6">
        <b-card class="text-center border-primary" style="padding-top:8%">
          <b-col sm="12" style="font-size:1.2em">    
            <h1 class="text-primary">จำนวนผู้ใช้งานทั้งหมด</h1>
          <h1>{{ result.users }}</h1>
          </b-col>
        </b-card>
      </b-col>
      
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";

import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";


import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
import DashboardService from "@/services/dashboard"
export default {
  components: {
    BRow,
    BCol,
    BCard,
    AnalyticsCongratulation,
    StatisticCardWithAreaChart,
  },
  data() {
    return {
      data: {},
      result:{
        users: 0,
        tickets: {
          inprogress: 0,
          closed: 0,
          cancel: 0,
        },
        stock: {
          onhand: 0,
          repair: 0,
          repairComplete: 0,
        },
        accounting: {
          wait: 0,
          approved: 0,
        },
      }
      
    };
  },
    computed: {
    dashboardPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("DB001")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    }
    },
  async created() {
    const result = await DashboardService.GetDashboard()
    this.result = result.data  
  },
};
</script>
